import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import Layout from "components/layout"
import Hero from "components/hero"
import Partners from "components/partners"
import Cards from "components/cards"
import SEO from "components/seo"
import ZipCodeForm from "components/zipcode_form"
import phone from "../images/phone.svg"
import shield from "../images/shield.svg"
import check from "../images/check.svg"
import trusted from "../images/trusted-choice-logo.svg"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Helmet } from "react-helmet"
const IndexPage = () => {


  const { t } = useTranslation();
  
  const renderMarkup = markUp => {
    return { __html: markUp }
  }

  const setRenderedMarkup = copyMarkup => {
    return <span dangerouslySetInnerHTML={renderMarkup(copyMarkup)} />
  }

  const gtag = () => {
    
    return <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-7RWCY0W3JD"></script>
          <script>
            {window.dataLayer = window.dataLayer || []}
            {function gtag(){window.dataLayer.push(arguments)}}
          {  gtag('js', new Date())}

            {gtag('config', 'G-7RWCY0W3JD')}
          </script>
        </Helmet>
  }


  const renderIndexPage = () => {
    return (
      <Layout>
        <gtag />
        <SEO title="Get a Quick Quote, Coverage and ID Cards in minutes! | InsureOnline.com." description="Compare & Get a Quick Cheap Auto Insurance Quote Online at Illinois & Indiana. Get free Car Insurance Quotes in Chicago, Illinois. Insure Online offers affordable car Insurance Quotes Online." keyword="Car insurance Illinois, car insurance Indiana, auto insurance Illinois, auto insurance Indiana, compare auto insurance online, compare car insurance online, cheap car insurance Illinois, cheap auto insurance Illinois, cheap can insurance Indiana, buy cheap auto insurance."/>

        {/* Hero */}
        <Hero isILVS={true}/>

        {/* Partners */}
        <Partners />

        {/* Cards */}
        <Cards />

        {/* Concierge Team */}
        <Container fluid>
          <Row className="concierge-team">
            <Col lg={6} className="text-container">
              <h2>{t("concierge.header")}</h2>
              <p>{t("concierge.subcopy")}</p>
              <span className="bold">{t("concierge.listHeader")}</span>
              <ol className="how-it-works">
                <li>{setRenderedMarkup(t("concierge.listItems1"))}</li>
                <li>{setRenderedMarkup(t("concierge.listItems2"))}</li>
                <li>{setRenderedMarkup(t("concierge.listItems3"))}</li>
                <li>{setRenderedMarkup(t("concierge.listItems4"))}</li>
              </ol>
            </Col>
            <Col lg={6} className="img-container">
              <img
               
                src="https://res.cloudinary.com/dixv1dkym/image/upload/w_1000,h_1000,c_fill,q_50/v1603128922/iStock-807410214_mqcvhv.jpg"
                alt="Auto Insurance Illinois"
              />
            </Col>
          </Row>
        </Container>

        {/* Protecting Drivers */}
        <Container fluid>
          <Row className="protecting-drivers">
            <Col lg={6} className="img-container">
              <img
              
                src="https://res.cloudinary.com/dixv1dkym/image/upload/w_1000,h_1000,c_fill,q_50/v1603129153/iStock-695470496_azp1qv.jpg"
                alt="Woman in a car smiling with her chin rested on her folded arms upon the car door frame"
              />
            </Col>
            <Col lg={6} className="text-container">
              <img className="trusted-choice" src={trusted} alt=""/>
              <h2>{t("promises.header")}</h2>
              <p> {t("promises.subcopy")}</p>
              <span> {t("promises.listHeader")}</span>
              <div className="promises-container">
                <ul>
                  <li>    
                    <img src={check} alt="" /> {t("promises.listItemsLeft1")}
                  </li>
                  <li>    
                    <img src={check} alt="" /> {t("promises.listItemsLeft2")}
                  </li>
                  <li>    
                    <img src={check} alt="" />  <a href='https://insureonline.com/Home/Contact'>24/7 Customer Support</a>
                  </li>
                </ul>
                <ul>
                  <li>    
                    <img src={check} alt="" /> {t("promises.listItemsRight1")}
                  </li>
                  <li>    
                    <img src={check} alt="" /> {t("promises.listItemsRight2")}
                  </li>
                  <li>    
                    <img src={check} alt="" /> {t("promises.listItemsRight3")}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Start Saving */}
        <Container fluid className="start-saving">
          <Container>
            <Row>
              <Col lg={{ span: 5, offset: 1 }}>
                <h2>{t("getQuote.header")}</h2>
              </Col>
            </Row>

            <Row>
              <Col lg={{ span: 5, offset: 1 }}>
                <h4>{t("getQuote.subheader")}</h4>
                <p className="mb-4">{t("getQuote.subcopy")}</p>
                
            <ZipCodeForm position="footer" />


                <div>
                  <img className="shield" src={shield} alt=""/>
                  {setRenderedMarkup(t("hero.disclaimer"))}
                </div>
              </Col>

              <Col lg={{ span: 4, offset: 1 }}>
                <h4> {t("getQuote.subheader2")}</h4>
                <p className="mb-4">{t("getQuote.subcopy2")}</p>

                <div>
                  <a className="phone" href="tel:+18443585605">
                    <img src={phone} alt=""/> 844-358-5605
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </Layout>
    )
  }
  return <>{
    (() => { 
        return renderIndexPage()
    })()
  }</>
}

export default IndexPage
